<!--
  PACKAGE_NAME : src\pages\esp\system\code\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 공통코드 목록
-->
<template>
    <esp-tree-grid :tree-grid="treeGrid" :id="treeGrid.id" />
</template>

<script>

import EspTreeGrid from "../../../../components/devextreme/esp-dx-tree-grid.vue";

export default {
    components: {EspTreeGrid},
    props: {},
    watch: {},
    data() {
        return {
            stylingMode: "outlined", // UI 스타일 모드: outlined, underlined, filled
            page: {
                sizeList: [], // 페이지 크기 목록
                size: null, // 현재 페이지 크기
                rowNum: 10 // 한 페이지에 표시할 행 수
            },
            checkboxKeys: [], // 체크박스 선택된 키 목록
            isReordered: false, // 데이터가 재정렬되었는지 여부
            checkBoxValue: true, // 체크박스 값
            searchType: {
                defaultObj: {
                    id: "ROOT",
                    codeValue: "ROOT",
                    codeNm: "전체" // 기본 검색 객체
                },
                defaultValue: "ROOT", // 기본 검색 값
                customTypes: {
                    delFl: null // 사용자 정의 검색 타입
                },
                paramsData: null // 검색 파라미터 데이터
            },
            showActionButtons: {
                select: true // 선택 버튼 표시 여부
            },
            treeGrid: {
                id: "codeList", // 트리 그리드 ID
                refName: "codeList", // 트리 그리드 참조 이름
                key: "codeKey",
                keyExpr: "id",
                parentIdExpr: "parentId",
                apiActionNm: {
                    select: "CODE_LIST_ALL",
                    merge: "CODE_LIST_MERGE",
                    delete: "CODE_LIST_DELETE"
                },
                dataSource: [], // 데이터 소스
                originDataSource: [], // 원본 코드 목록
                expandedRowKeys: [], // 확장된 행 키 목록
                selectedRowkeys: [], // 선택된 행 키 목록
                focusedRowKey: null, // 포커스된 행 키
                checkBoxValue: true, // 체크박스 값
                selectedRowsData: [], // 선택된 행 데이터
                recursive: true, // 재귀 여부
                selectionMode: "all", // 선택 모드: all, single, multiple
                allowDropInsideItem: true, // 항목 내부 드롭 허용 여부
                allowReordering: true, // 재정렬 허용 여부
                showDragIcons: true, // 드래그 아이콘 표시 여부
                selectedData: null, // 선택된 데이터
                filterRow: {
                    visible: true // 필터 행 표시 여부
                },
                sort: '+codeOrd,+codeNm', // 정렬
                sortKey: "codeOrd",
                height: null, // 그리드 높이
                showColumnHeaders: true, // 열 헤더 표시 여부
                showRowLines: true, // 행 라인 표시 여부
                showColumnLines: true, // 열 라인 표시 여부
                showBorders: false, // 테두리 표시 여부
                columnAutoWidth: true, // 열 자동 너비 조정 여부
                searchPanel: {
                    visible: false, // 검색 패널 표시 여부
                    placeholder: "", // 검색 패널 플레이스홀더
                    searchVisibleColumnsOnly: true, // 보이는 열만 검색 여부
                    width: 200 // 검색 패널 너비
                },
                editing: {
                    allowAdding: true, // 추가 허용 여부
                    allowUpdating: true, // 업데이트 허용 여부
                    useIcons: true, // 아이콘 사용 여부
                    mode: "batch" // 편집 모드: batch, cell, row
                },
                selection:{
                    recursive: true, // 재귀 여부
                },
                rowDragging: {
                    allowDropInsideItem: true, // 항목 내부 드롭 허용 여부
                    allowReordering: true, // 재정렬 허용 여부
                    showDragIcons: true // 드래그 아이콘 표시 여부
                },
                columns: [
                    {
                        caption: '코드명',
                        dataField: 'codeNm',
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        requiredRule: {
                            message: '코드명은 필수 항목입니다.'
                        },
                    },
                    {
                        caption: '코드값',
                        dataField: 'codeValue',
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        requiredRule: {
                            message: '코드값은 필수 항목입니다.'
                        },
                    },
                    {
                        caption: '코드키',
                        dataField: 'codeKey',
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        requiredRule: {
                            message: '코드키은 필수 항목입니다.'
                        },
                    },
                    {
                        caption: '비고',
                        dataField: 'description',
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    {
                        caption: 'ID',
                        dataField: 'id',
                        alignment: 'center',
                        visible: false,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    {
                        caption: '삭제',
                        cellTemplate: 'removeTemplate',
                        dataField: 'id',
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                    },
                    // {
                    //     alignment: "center", // 정렬: left, center, right
                    //     allowEditing: true, // 편집 허용 여부
                    //     allowExporting: true, // 내보내기 허용 여부
                    //     allowFiltering: true, // 필터링 허용 여부
                    //     allowGrouping: true, // 그룹화 허용 여부
                    //     allowHeaderFiltering: true, // 헤더 필터링 허용 여부
                    //     allowSorting: true, // 정렬 허용 여부
                    //     calculateSortValue: null, // 정렬 값 계산 함수
                    //     calculateCellValue: null, // 셀 값 계산 함수
                    //     calculateDisplayValue: null, // 표시 값 계산 함수
                    //     caption: "caption", // 열 캡션
                    //     cellTemplate: null, // 셀 템플릿
                    //     cssClass: null, // CSS 클래스
                    //     customizeText: null, // 텍스트 커스터마이징 함수
                    //     dataField: "codeNm", // 데이터 필드
                    //     dataType: "string", // 데이터 타입: string, number, date, boolean
                    //     editCellTemplate: null, // 편집 셀 템플릿
                    //     editorOptions: null, // 편집기 옵션
                    //     editorType: null, // 편집기 타입
                    //     filterValue: null, // 필터 값
                    //     fixed: false, // 고정 여부
                    //     fixedPosition: null, // 고정 위치: left, right
                    //     format: null, // 포맷
                    //     groupIndex: null, // 그룹 인덱스
                    //     headerCellTemplate: null, // 헤더 셀 템플릿
                    //     height: null, // 높이
                    //     setCellValue: null, // 셀 값 설정 함수
                    //     sortOrder: null, // 정렬 순서: asc, desc
                    //     validationRules: [], // 유효성 검사 규칙
                    //     visible: true, // 표시 여부
                    //     width: null, // 너비
                    //     minWidth: 50, // 최소 너비
                    //     lookup: {
                    //         dataSource: [], // 조회 데이터 소스
                    //         displayExpr: "displayField", // 표시 필드
                    //         valueExpr: "valueField", // 값 필드
                    //         allowClearing: true // 지우기 허용 여부
                    //     },
                    //     headerFilter: {
                    //         dataSource: [] // 헤더 필터 데이터 소스
                    //     },
                    //     requiredRule: {
                    //         message: "This field is required" // 필수 규칙 메시지
                    //     },
                    //     patternRule: {
                    //         pattern: "^[a-zA-Z0-9]+$", // 패턴 규칙
                    //         message: "Only alphanumeric characters are allowed" // 패턴 규칙 메시지
                    //     },
                    //     customRule: {
                    //         callback: null, // 커스텀 규칙 콜백 함수
                    //         message: "Custom validation message" // 커스텀 규칙 메시지
                    //     }
                    // }
                ]
            },
        };
    },
    computed: {
    },
    methods: {
    },
    created() {
    },
    mounted() {
        // this.mountedData();
    },
};
</script>

